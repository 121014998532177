<template>
    <v-sheet color="transparent">
       <v-list-item>
            <v-list-item-content>
            </v-list-item-content>
            <v-list-item-action>
                <v-btn icon @click="$emit('close')"><v-icon>mdi-close</v-icon></v-btn>
            </v-list-item-action>
        </v-list-item> 
        <v-simple-table v-if="email" >
            <tbody>
                <tr>
                    <th>Template</th>
                    <td>{{email.template.name}}</td>
                </tr>
                <tr v-for="k in Object.keys(email.template.data)" :key="k">
                    <th>{{mwutils.prettyPrint(k)}}</th>
                    <td>{{email.template.data[k]}}</td>
                </tr>
                <tr>
                    <th>Started</th>
                    <td>
                        <span v-if="email.delivery">
                            {{(mwtimeutils.parseDateToMoment(email.delivery.startTime))}}
                        </span>
                    </td>
                </tr>
                <tr>
                    <th>Ended</th>
                    <td>
                        <span v-if="email.delivery">
                            {{mwtimeutils.parseDateToMoment(email.delivery.endTime)}}
                        </span>
                    </td>
                </tr>
                <tr>
                    <th>Delivery Attempts</th>
                    <td>
                        <span v-if="email.delivery">
                            {{email.delivery.attempts}}
                        </span>
                    </td>
                </tr>
                <tr>
                    <th>Status</th>
                    <td>
                        <span v-if="email.delivery">
                            {{email.delivery.state}}
                        </span>
                    </td>
                </tr>
                <tr>
                    <th>Accepted</th>
                    <td>
                        <span v-if="email.delivery.info">
                            {{email.delivery.info.accepted ? 'TRUE' : 'FALSE' }}
                        </span>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
        <mw-dialog v-model="dialog1" submitBtn @submit="resend">
            <template v-slot:button>
                <v-btn @click="dialog1 = true" text color="primary" block>Resend this email </v-btn>
            </template>
            <template v-slot:dialog-body>
                Are you sure you want to resend this email?
            </template>
        </mw-dialog>
        <mw-dialog v-model="dialog2" submitBtn @submit="cancel">
            <template v-slot:button>
                <v-btn @click="dialog2 = true" text color="red" block>Cancel email </v-btn>
            </template>
            <template v-slot:dialog-body>
                Are you sure you want to cancel this email? This can not undo emails that have already started sending
            </template>
        </mw-dialog>
    </v-sheet>
</template>

<style lang="less" scoped>
    tbody tr td {
        word-break: break-all
    }
</style>

<script>

import MwDialog from "@c/ui/MwDialog"

export default {
    name: "EmailSummary",
    props: {
        id: {type: String},
    },
    data: () => {
        return {
            dialog1: false,
            dialog2: false,
        }
    },
    computed: {
        email(){
            return this.$store.state.emails.data[this.id]
        }
    }, 
    components: {
        MwDialog
    },
    methods: {
        resend(){
            this.$store.dispatch("emails/insert", {template: this.email.template, toUids: this.email.toUids, message: this.email.message } )
        },
        cancel(){
            this.$store.dispatch("emails/patch", {id: this.id, send_status: "cancelled"})
        }
    }
}
</script>