<template>
	<v-sheet>

		<v-data-table :headers="columns" :items="comms" @click:row="(item) => focus = item">
			<template v-slot:item.template="{item}">
				{{ item.template ? item.template.name : item.template }}
			</template>
			<template v-slot:item.created_at="{item}">
				{{ getDate(item.created_at) }}
			</template>
			<template v-slot:item.status="{item}">
				{{item.delivery ? item.delivery.state : item.delivery}}
			</template>
		</v-data-table>
		<secondary-navigation show-back>
            <template v-slot:main>
                <email-summary @close="focus = null" v-if="focus && focus.type == 'email'" :id="focus.id"></email-summary>
                <sms-summary @close="focus = null" v-if="focus && focus.type == 'sms'" v-model="focus"></sms-summary>
            </template>
        </secondary-navigation>
	</v-sheet>
</template>

<style lang="less">
@import "../../assets/css/variables.less";
.pointer {
	cursor: pointer;
}
tr.active {
    background: @lightest;
}
</style>

<script type="text/javascript">
import SecondaryNavigation from "@c/navigation/Secondary";
import EmailSummary from "@c/communication/EmailSummary";
import SmsSummary from "@c/communication/SmsSummary";
// import MwListDefined from "@c/ui/MwListDefined"

export default {
	name: "CommunicationHistory",
	props: {
		id: { type: String }, // @doc the id string of the object - will add a new document if not passed
	},
    data: () => ({
		dialog: false,
        focus: null,
		columns: [
			{value: "created_at", text: "Created", default: true}, 
			{value: "type", text: "Type", default: true}, 
			{value: "template", text: "Template", default: true},
			{value: "status", text: "Status", default: true, width: "30%" },
		]
    }),
	computed: {
		emails() {
			return this.$store.getters.getByKeyContains("emails","toUids", this.id );
		},
		messages(){
			return this.$store.getters.getByKey("messages", "user", this.id );
		},
		comms(){
			let emails = this.emails.map( a => {
				a.type = "email";
				return a;
			})
			let messages = this.messages.map( a => {
				a.type = "sms";
				return a;
			})
			let comms = emails.concat(messages)
			return this.mwutils.sortByDate(comms, "created_at", this.direction );
		}
	},
	components: {
		SecondaryNavigation,
		EmailSummary, 
		SmsSummary
		// MwListDefined,
	},
	methods: {
		getDate(datefield){
			if( !datefield ){
				return "";
			}
			return this.mwtimeutils.formatAsDateTimeString(datefield)
		}
	},
	created() {
		this.$store.dispatch("emails/fetchByUser", this.id);
		this.$store.dispatch("messages/fetchByKey",{key: "user", value: this.id});
	},
};
</script>