<template>
    <v-sheet color="transparent">
       <v-list-item>
            <v-list-item-content>
            </v-list-item-content>
            <v-list-item-action>
                <v-btn icon @click="$emit('close')"><v-icon>mdi-close</v-icon></v-btn>
            </v-list-item-action>
        </v-list-item> 
        <v-simple-table>
            <tbody>
              
                <tr>
                    <th>To</th>
                    <td>{{value.to}}</td>
                </tr>
                <tr>
                    <th>Status</th>
                    <td>{{value.delivery.state}}</td>
                </tr>
                <tr>
                    <th>Message</th>
                    <td>
                        <small>{{value.body}}</small> 
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
    </v-sheet>
</template>

<style lang="less" scoped>
    tbody tr td {
        word-break: break-all
    }
</style>

<script>


export default {
    name: "SmsSummary",
    props: {
        value: {type: Object},
    },
    data: () => {
        return {
            dialog1: false,
            dialog2: false,
        }
    },
    computed: {
        
    }, 
    components: {
    },
    methods: {

    }
}
</script>